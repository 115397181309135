*{
    font-family: 'Public Sans', sans-serif;
}
/**:focus-visible {
    border: 2px solid #22489C;
}*/
input,select{
    border:none;
    border-bottom:1px solid rgb(114, 114, 114)
}

.custom-shape-divider-top-1621034661 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1621034661 svg {
    position: relative;
    display: block;
    width: calc(176% + 1.3px);
    height: 57px;
    transform: rotateY(180deg);
}

.custom-shape-divider-top-1621034661 .shape-fill {
    fill: #4d5e9b;
}
button{
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin:0px 5px;
}
button:hover{
    background-color: rgba(232, 232, 232, 0.411);
    color:#244c9cd3
}
.buttonIconsRtl{
  margin: 0 -4px 0 8px;
}
.contact{
    color:black;
    background-color: white;
    border: none;
    border-radius: 10px;
    padding: 10px;
    margin:0px 5px;
}

#okError:hover {
    color: lightgray;
}

.contact:hover{
    background-color: rgba(232, 232, 232, 0.411);
    color:#244c9cd3
}
a{
    margin:0px 30px;
    text-decoration: none;
    color: white;
}
.highContrast{
    *,input::placeholder,button,.custom-shape-divider-top-1621034661 .shape-fill {
        background-color: black !important;
        color: yellow;
        fill:black;
    }
}
ul{
    margin:0px;
}
.form-select{
    padding: 0px 20px
}

p{
    font-size: 1.125rem;
}

/*
**styles for the FAQ page
*/
dl.faq .ltrButton {
    margin: 0;
    padding: 0;
    margin-top: 1em;
    font-weight: bold;
    font-size: 110%;
    border: thin solid transparent;
    background-color: transparent;
    padding-left: 0.125em;
    text-align: left;
  }

  .rtlButton {
    margin: 0;
    padding: 0;
    margin-top: 1em;
    font-weight: bold;
    font-size: 110%;
    border: thin solid transparent;
    background-color: transparent;
    padding-right: 0.125em;
    text-align: right;
  }
  
  dl dd {
    margin: 0;
    padding: 0;
    margin-left: 1.5em;
  }
  
  dl.faq .desc {
    margin: 0;
    padding: 0.5em;
    font-size: 110%;
    display: none;
    //background-color: #fffedb;
    text-align: left;
  }

  .descRtl {
    margin: 0;
    padding: 0.5em;
    font-size: 110%;
    display: none;
    //background-color: #fffedb;
    text-align: right;
  }
  
  dl.faq button:hover,
  dl.faq button:focus {
    background-color: #eee;
  }
  
  dl.faq button:focus {
    border-color: #630;
  }
  
  dl.faq button:hover {
    text-decoration: underline;
  }
  
  dl.faq button:active {
    background-color: #bbb;
  }
  
  dl.faq button[aria-expanded="false"]::before {
    content: url('../right-arrow-brown.png');
    padding-right: 0.35em;
    text-align: left;
  }
  
  dl.faq button[aria-expanded="true"]::before {
    content: url('../down-arrow-brown.png');
    padding-right: 0.35em;
    text-align: left;
  }

  .MuiInputLabel-formControl{
    left:auto !important;
  }

  #MiddleName-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 99%;
    height: 100%;
  }

  .SpanText-Bold-With-Indent {
    font-weight: bold;
    padding-left: 6px;
    padding-right: 6px;
  }

  .MuiInput-underline.Mui-error:after {
    border-bottom-color: #b30000 !important;
  }

  .MuiFormLabel-root {
    line-height: 1.5 !important;
    color: #212529 !important;
  }

  .MuiFormLabel-root.Mui-error{
    color: #b30000 !important;
    
  }

  .MuiFormLabel-asterisk.Mui-error {
    color: #b30000 !important;
  }

  .MuiFormHelperText-root.Mui-error{
    color: #b30000 !important;
    font-weight: bold !important;
    font-size: 1rem !important;
  }

  .MuiInputLabel-shrink {
    transform: translate(0, -3px) !important;
  }

  label[for="submitcheckbox"]>.MuiFormControlLabel-label{
    display: none;
  }
  bdi{
    font-weight: bold;
  }

  h1:focus{
    outline: none;
  }

  #langIcon {
    min-width: 48px;
    min-height: 48px;
  }